//
/// RISKS
//
export const data = [
  "upozornenie Národnej banky Slovenska, prípadne inej verejnej autority na aktivity takého subjektu",
  "vedenie súdneho alebo administratívneho konania proti poskytovateľovi služieb",
  "potreba zadať, zdieľať alebo komukoľvek poslať privátne kľúče, či heslo od peňaženky alebo účtu",
  "poskytovateľ služby garantuje ziskovosť alebo návratnosť investície (osobitne v krátkom časovom horizonte)",
  "použitie lákavých fráz „zarábajte online“ alebo „pasívny príjem“",
  "veľkolepé tvrdenia o vlastnom kryptoaktíve „nový Bitcoin/lepší ako Bitcoin“ s garantovaním budúceho zhodnotenia",
  "požiadavky na realizáciu ďalšej investície, pre účely výberu skoršej investície",
  "požiadavky na zaslanie kryptoaktív na cudzie či neznáme adresy peňaženiek, ktoré nie sú jasne asociované s poskytovateľom predmetných služieb",
  "prísľub spätného zaslania násobku kryptoaktív po zaslaní určitého množstva zo strany užívateľa",
  `štruktúra poskytovateľa javí <a href="https://www.pracanadoma-skusenosti.eu/mlm-sietovy-marketing/" target="_blank">znaky MLM (multi-level marketingu)</a> a odmeňovanie za používanie služby čiastočne závisí od schopností vytvorenia vlastnej predajnej siete`,
  "ponuky na poskytnutie služby alebo investovanie prostredníctvom nevyžiadanej e-mailovej komunikácie, nevyžiadaného kontaktovania pomocou sociálnych sietí, prípadne nevyžiadaných telefonických hovorov",
  `marketingová komunikácia založená na <a href="http://www.infolib.sk/files/infos_2017_prezentacie/stopkova-social-bots-prostredi-socialnych-sieti.pdf" target="_blank">využívaní botov</a>`,
  "absencia formálneho zmluvného dokumentu pri poskytovaní služieb",
  "recenzie k produktu obsahujú zmienky o problémoch s výberom prostriedkov investície, prípadne proklamovaného zisku",
  "vytvorenie a predaj vlastného kryptoaktíva bez existencie využitia (utility)a trhu na jeho budúce odpredanie",
  "kryptoaktívum je možné kúpiť a zamieňať výlučne u poskytovateľa služby (hodnota kryptoaktíva je určovaná poskytovateľom, nie mechanizmom voľného trhu, napríklad sa neobchoduje u iných poskytovateľov služby zmenárne/burzy/peňaženky virtuálnej meny)",
  `prevádzkovanie trading, signálových alebo <a href="https://finex.cz/anatomie-pump-dump-schematu-jak-tyto-podvodne-praktiky-poznat/" target="_blank">Pump & Dump komunitných skupín (telegram, discord...)</a>`,
  "poskytovanie služby prenájmu výpočtového výkonu za účelom ťažby kryptoaktív, napr. 'cloud-mining'",
];
//
/// TEXT IN FLAGS
//
export const textInFlags = [
  "Nepredstavuje riziko",
  "Predstavuje malé riziko",
  "Predstavuje stredné riziko",
  "Predstavuje veľké riziko",
];
