<template>
  <section class="warning">
      <div class="warning-border">
        <div class="warning-wrap">
            <img src="../../public/img/warning.svg" alt="warning" />
            <p>
                Scamafór má byť len pomocným nástrojom a jeho účelom nie je nahradiť
                obozretné preverenie investičného produktu alebo poskytovateľa služieb
                súvisiacich s kryptoaktívami. Na ceste k investovaniu nie je opatrnosti
                nikdy dosť!
            </p>
        </div>
      </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
    .warning{
        width: 100%;
        background: #fff;
        padding: 0 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .warning-border{
        max-width: 970px;
        height: 172px;
        border: 1px solid #E7E7F1;
        border-radius: 5px;
        margin: 84px 0;

        display: flex;
        justify-content: center;
        align-items: center;
    }
    .warning-wrap{
        width: 67%; /*656px*/

        display: flex;
        justify-content: center;
        align-items: center;
       
    }
    .warning-wrap img{
        width: 46px;
        height: 40px;
    }
    .warning-wrap p{
        color: #7E7E9C;
        font-size: 0.875rem;    /*14px */
        line-height: 1.5rem;    /*24px*/
        margin-left: 40px;
    }
    /* media queries */
    @media only screen and (max-width: 1024px){
        .warning-wrap{
            width: 80%;
        }
        .warning-border{
            width: 80%;
        }
    }
    @media only screen and (max-width: 768px){
        .warning-border{
            margin: 40px 0;
        }
        .warning-wrap{
            flex-direction: column;
            align-items: center;
            width: 100%;
            padding: 1rem;
        }
        .warning-wrap img{
            width: 48px;
            height: 48px;
        }
        .warning-wrap p{
            margin: 0;
            text-align: center;
        }
    }

    @media only screen and (max-width: 620px){
         .warning{
            width: 100%;
        }
        .warning-border{
            width: 100%;
            height: 224px;
            
        }
        
        .warning-wrap img{
            margin: 0.5rem;
        }
    }
    
</style>
