<template>
  <section class="post-header">
      <div class="post-header-wrap">
        <div class="post-header-text">
            <h1>SCAM<span>AFOR</span></h1>
            <p>Kliknite na rizikové faktory <br> a SCAMAFOR sa zmení farbou podľa rizika</p>
        </div>

        <img src="../../public/img/bitcoins.svg" alt="">

      </div>
  </section>
</template>

<script>
export default {};
</script>

<style lang="css" scoped>
.post-header{
    /* height: 354px; */
    background:#F6F6FC;

    display: flex;
    align-items: center;
}
.post-header-wrap{
    width: 1150px;
    padding: 1rem;
    margin: 0 auto;

    display: flex;
    justify-content: space-between;
    align-items: center;
    
}
/* left side */

.post-header-text h1{
    font-weight: 700;
    font-size: 4rem;
    line-height: 4.625rem;
    color:#191642;
}
h1 span{
    color:#76739f;
}
.post-header-text p{
    color: #191642;
    font-size: 32px;
    line-height: 42px;
    font-weight: 500;
}
/* right side */


/* media queries */
@media only screen and (max-width: 1024px) {
  .post-header-wrap {
    flex-direction: column;
    text-align: center;
  }
  .post-header-text h1{
      margin-bottom: 1rem;
  }
  .post-header-text p{
      margin-bottom: 1rem;
  }
 
  @media only screen and (max-width: 500px){
      .post-header-text h1{
          font-size: 2.75rem; /*44px */
          line-height: 54px;
      }
      .post-header p{
          font-size: 20px;
          line-height: 30px;
      }
      .post-header-wrap img{
          max-width: 270px;
      }
  }
}
</style>
