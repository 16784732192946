<template>
  <section class="download">
    <div class="download-wrapper">
      <div class="crypto-img"></div>

      <div class="right-side-wrap">
        <h3>
          Stiahnite si zdarma <br />
          e-book Scamafor
        </h3>

        <a @click="blankPage()" class="download-btn" >
        
          <p>stiahnuť ebook</p>
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  methods: {
    blankPage() {
      window.open("https://scamafor.blockchainslovakia.sk/scamafor-ebook.pdf")
    }
  },
};
</script>

<style lang="css" scoped>
.download {
  width: 100%;
  background: #fff;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
}

.download-wrapper {
  max-width: 970px;
  width: 100%;
  height: 244px;
  background: #191642;
  border-radius: 5px;
  margin-bottom: 84px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
.crypto-img {
  width: 100%;
  height: 100%;
  flex: 1;
  background: url("../../public/img/download.svg");
  background-size: cover;
  border-radius: 5px;
}

.right-side-wrap {
  flex: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}
.right-side-wrap h3 {
  font-size: 32px;
  font-weight: 500;
  line-height: 42px;
  color: #ffffff;
  margin-bottom: 24px;
}
.right-side-wrap a{
  cursor: pointer;
}
.download-btn {
  width: 270px;
  text-align: center;
  background: #3c3cdd;
  border-radius: 5px;
  text-decoration: none;
  transition: 0.2s ease;
}
.download-btn:hover,
.download-btn:focus {
  background: #3333bb;
}
.download-btn p {
  padding: 10px 0;
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: #ffffff;
}
.download-btn p::before {
  content: url("../../public/img/download-icon.svg");
  vertical-align: middle;
  margin-right: 12px;
}
/* media queries */
@media only screen and (max-width: 1024px) {
  .download-wrapper {
    width: 80%;
  }
  .right-side-wrap h3{
    text-align: center;
  }
}
@media only screen and (max-width: 768px) {
  .download-wrapper {
    height: 400px;
    flex-direction: column;
    margin-bottom: 44px;
  }
  .right-side-wrap h3 {
    font-size: 28px;
    margin-top: 18px;
  }
}
@media only screen and (max-width: 620px) {
  .download-wrapper {
    width: 100%;
  }
}
</style>
